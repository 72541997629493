import { ReactNode } from "react"

interface MainProps {
  children?: ReactNode
  className?: string
}

export default function Main({ children, className }: MainProps) {
  return (
    <main className={`min-h-screen ${className ?? ""} bg-slate-200`}>
      {children}
    </main>
  )
}
