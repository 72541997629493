import { FormEvent, useState } from "react"
import useValidate from "../../../hooks/useValidate"
import ErrorPage from "../../error"
import { Link, useNavigate } from "react-router-dom"
import useForm from "../../../hooks/useForm"
import Main from "../../../components/Main"
import Header from "../../../components/Header"
import Board from "../../../components/Board"
import SectionTitle from "../../../components/SectionTitle"
import Radio from "../../../components/forms/radio"
import { OptionsB } from "../../../hooks/Options"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"
import Submit from "../../../components/forms/submit"
import Loading from "../../../components/Loading"
import Popup from "../../../components/Popup"
import parse from "html-react-parser"
import CreateOptions from "./options"

export default function OutrosSintomas() {
  const navigate = useNavigate()
  const [open, isOpen] = useState(false)
  const [popup, setPopup] = useState(<Loading />)

  if (!useValidate.CheckStep("drk-p-2")) {
    return (
      <ErrorPage
        backToUrl={"/principais-sintomas"}
        backToUrlLabel="Informar Sintomas Principais"
      >
        Você não concluiu o passo anterior
      </ErrorPage>
    )
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    useForm.HandleSubmit(e, "drk-p-3")
    isOpen(true)
    setPopup(<Loading />)

    try {
      const formData = new FormData()

      formData.append("drkp0", localStorage.getItem("drk-p-0") ?? "")
      formData.append("drkp1", localStorage.getItem("drk-p-1") ?? "")
      formData.append("drkp2", localStorage.getItem("drk-p-2") ?? "")
      formData.append("drkp3", localStorage.getItem("drk-p-3") ?? "")

      const api = await fetch(
        "https://drkleiner.com.br/wp-admin/admin-ajax.php?action=form_quiz",
        {
          method: "POST",
          body: formData,
        }
      )

      const { success, content } = await api.json()

      if (!success) {
        setPopup(<>{parse(content)}</>)
      } else {
        localStorage.removeItem("drk-p-0")
        localStorage.removeItem("drk-p-1")
        localStorage.removeItem("drk-p-1-opt")
        localStorage.removeItem("drk-p-2")
        localStorage.removeItem("drk-p-2-opt")
        localStorage.removeItem("drk-p-3")
        navigate("/sucesso")
      }
    } catch (e) {
      setPopup(<>Ocorreu um erro. Tente novamente mais tarde.</>)
    }
  }

  return (
    <Main>
      <Popup isOpen={open} setOpenState={isOpen}>
        {popup}
      </Popup>
      <Header>Questionário de Triagem para Cândida</Header>
      <Board>
        <SectionTitle>Outros Sintomas</SectionTitle>
        <form onSubmit={(e) => handleSubmit(e)}>
          {CreateOptions().map(
            (opt) =>
              opt.name !== undefined && (
                <Radio
                  required={true}
                  className="radio-wrapper"
                  options={OptionsB(opt.name)}
                  key={`opt-loop-${opt.name}`}
                >
                  {opt.label}
                </Radio>
              )
          )}
          <div className="flex justify-between">
            <Link to={"/principais-sintomas"} className="btn-site secondary">
              <FaAngleLeft size={16} />
              Voltar
            </Link>
            <Submit>
              Próximo
              <FaAngleRight size={16} />
            </Submit>
          </div>
        </form>
      </Board>
    </Main>
  )
}
