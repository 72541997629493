import { Link, useNavigate } from "react-router-dom"
import Board from "../../../components/Board"
import Header from "../../../components/Header"
import Main from "../../../components/Main"
import useForm from "../../../hooks/useForm"
import { FormEvent, useEffect, useState } from "react"
import ErrorPage from "../../error"
import SectionTitle from "../../../components/SectionTitle"
import Submit from "../../../components/forms/submit"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"
import useValidate from "../../../hooks/useValidate"
import Radio from "../../../components/forms/radio"
import { Options } from "../../../hooks/Options"
import CreateOptions from "./options"
import { compileJson, uncompileJson } from "../../../functions/JSON"

export default function SintomasPrincipais() {
  const navigate = useNavigate()

  const [options, setOptions] = useState<any>()

  useEffect(() => {
    const savedOptions = localStorage.getItem("drk-p-2-opt")
    if (savedOptions) {
      setOptions(uncompileJson(savedOptions))
    }
  }, [])

  if (!useValidate.CheckStep("drk-p-1")) {
    return (
      <ErrorPage backToUrl={"/"} backToUrlLabel="Informar Sintomas Físicos">
        Você não concluiu o passo anterior
      </ErrorPage>
    )
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    useForm.HandleSubmit(e, "drk-p-2")
    localStorage.setItem("drk-p-2-opt", compileJson(options))
    navigate("/outros-sintomas")
  }

  return (
    <Main>
      <Header>Questionário de Triagem para Cândida</Header>
      <Board>
        <SectionTitle>Principais Sintomas</SectionTitle>
        <form onSubmit={(e) => handleSubmit(e)}>
          {CreateOptions().map(
            (opt) =>
              opt.name !== undefined && (
                <Radio
                  required={true}
                  className="radio-wrapper"
                  options={Options(opt.name, undefined, options?.[opt.name])}
                  key={`opt-loop-${opt.name}`}
                  handleChange={(e) => {
                    setOptions({ ...options, [opt.name]: e.target.value })
                  }}
                >
                  {opt.label}
                </Radio>
              )
          )}
          <div className="flex justify-between">
            <Link to={"/historico"} className="btn-site secondary">
              <FaAngleLeft size={16} />
              Voltar
            </Link>
            <Submit>
              Próximo
              <FaAngleRight size={16} />
            </Submit>
          </div>
        </form>
      </Board>
    </Main>
  )
}
