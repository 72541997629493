import { unjson } from "./JSON"

interface UserDataProps {
  email: string
  fullName: string
  phone: string
  sexo: "masculino" | "feminino"
}

const GetUserData = () => {
  const user = localStorage.getItem("drk-p-0")
  return atob(user!)
}

const GetUserGenre = () => {
  const user = unjson(GetUserData()) as UserDataProps
  return user.sexo === "masculino" ? "m" : "f"
}

export { GetUserData, GetUserGenre }
