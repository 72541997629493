import { ReactNode } from "react"

interface BoardProps {
  children?: ReactNode
  className?: string
}

export default function Board({ children, className }: BoardProps) {
  return (
    <div className={`p-4 max-w-[768px] mx-auto ${className ?? ""}`}>
      {children}
    </div>
  )
}
