import { ReactNode } from "react"

interface SubmitProps {
  children?: ReactNode
  className?: string
  id?: string
}

export default function Submit({ children, className, id }: SubmitProps) {
  return (
    <div className={`input-submit flex justify-center ${className ?? ""}`}>
      <button
        type="submit"
        id={id ?? ""}
        className="inline-flex items-center bg-slate-700 text-white py-2 px-4 rounded-md"
      >
        {children}
      </button>
    </div>
  )
}
