import { ReactNode } from "react"

interface TextProps {
  label?: string
  children?: ReactNode
  className?: string
  id?: string
  error?: {
    message?: string
  }
}

export default function Text({
  id,
  className,
  label,
  children,
  error,
}: TextProps) {
  return (
    <label
      htmlFor={id ?? ""}
      className={`input-text my-6 block w-full ${className ?? ""}`}
    >
      <p className="font-bold">{label}</p>
      {children}
      {error && (
        <span
          className="text-xs text-red-600 block mt-2 font-semibold"
          style={{ lineHeight: "16px" }}
        >
          {error.message}
        </span>
      )}
    </label>
  )
}
