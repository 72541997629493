import Slugify from "../functions/Slugify"

export function Options(id: string, points?: number[], checked?: string) {
  return [
    {
      id: `opt-${id}-0`,
      value: points ? points[0] : 0,
      label: "Não",
      name: `opt-${id}`,
      checked: checked
        ? points
          ? points[0] === parseInt(checked)
          : checked === "0"
        : false,
    },
    {
      id: `opt-${id}-1`,
      value: points ? points[1] : 3,
      label: "Ocasional e/ou leve",
      name: `opt-${id}`,
      checked: checked
        ? points
          ? points[1] === parseInt(checked)
          : checked === "3"
        : false,
    },
    {
      id: `opt-${id}-2`,
      value: points ? points[2] : 6,
      label: "Frequente e/ou moderadamente grave",
      name: `opt-${id}`,
      checked: checked
        ? points
          ? points[2] === parseInt(checked)
          : checked === "6"
        : false,
    },
    {
      id: `opt-${id}-3`,
      value: points ? points[3] : 9,
      label: "Muito frequente e/ou muito grave ou incapacitante",
      name: `opt-${id}`,
      checked: checked
        ? points
          ? points[3] === parseInt(checked)
          : checked === "9"
        : false,
    },
  ]
}

export function OptionsB(id: string) {
  return Options(id, [0, 1, 2, 3])
}

export function createOption(label: string) {
  return {
    name: Slugify(label),
    label,
  }
}
