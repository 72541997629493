import { ReactNode } from "react"

interface HeaderProps {
  children?: ReactNode
  className?: string
}

export default function Header({ children, className }: HeaderProps) {
  return (
    <div
      className={`section-title bg-slate-700 text-white font-bold text-center p-4 ${
        className ?? ""
      }`}
    >
      {children}
    </div>
  )
}
