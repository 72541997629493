import { ReactNode } from "react"

interface SectionTitleProps {
  children?: ReactNode
  className?: string
}

export default function SectionTitle({
  children,
  className,
}: SectionTitleProps) {
  return (
    <h2
      className={`text-slate-700 font-bold text-center text-lg mb-6 mt-8 ${
        className ?? ""
      }`}
    >
      {children}
    </h2>
  )
}
