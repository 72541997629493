import { z } from "zod"
import maskPhone from "../functions/Maskphone"

const CheckStep = (step: string) => {
  const previousStep = localStorage.getItem(step)
  if (previousStep == null) {
    return false
  }

  return true
}

const DadosSchema = z.object({
  fullName: z
    .string({
      required_error: "O nome é obrigatório",
    })
    .min(3, {
      message: "O nome precisa conter no mínimo 3 caracteres",
    }),
  email: z
    .string({
      required_error: "O e-mail é obrigatório",
    })
    .email({
      message: "E-mail inválido",
    }),
  phone: z
    .string({
      required_error: "O telefone é obrigatório. Informe DDD + Telefone",
    })
    .min(10, {
      message: "O telefone deve conter no mínimo 10 caracteres",
    })
    .max(15, {
      message: "O telefone deve conter no máximo 15 caracteres",
    })
    .transform((e) => maskPhone(e)),
  sexo: z.string({ required_error: "Informe o sexo" }),
})

const useValidate = {
  CheckStep,
  DadosSchema,
}

export default useValidate
