import { useState } from "react"
import { createOption } from "../../../../hooks/Options"

const CreateOptions = () => {
  const [options] = useState([
    createOption(`Sonolência`),
    createOption(`Irritabilidade ou inquietação`),
    createOption(`Falta de coordenação`),
    createOption(`Dificuldade de concentração`),
    createOption(`Mudanças de humor frequentes`),
    createOption(`Dor de cabeça`),
    createOption(`Tontura/perda de equilíbrio`),
    createOption(`Pressão acima das orelhas, ou sensação de inchaço na cabeça`),
    createOption(`Tendência a machucar-se facilmente`),
    createOption(`Irritações na pele ou coceira frequente`),
    createOption(`Dormência, formigamento de alguma parte do corpo`),
    createOption(`Indigestão ou azia`),
    createOption(`Sensibilidade ou intolerância alimentar`),
    createOption(`Muco nas fezes`),
    createOption(`Coceira retal`),
    createOption(`Boca ou garganta seca`),
    createOption(`Irritações ou bolhas na boca`),
    createOption(`Mau hálito`),
    createOption(`Odor nos pés, corpo ou cabelo que não alivia pela lavagem`),
    createOption(`Congestão nasal ou gotejamento pós-nasal`),
    createOption(`Coceira no nariz`),
    createOption(`Dor de garganta`),
    createOption(`Laringite, perda de voz`),
    createOption(`Tosse ou bronquite recorrente`),
    createOption(`Dor ou aperto no peito`),
    createOption(`Chiado ou falta de ar`),
    createOption(`Urgência para urinar ou urina com muita frequência`),
    createOption(`Ardência ao urinar`),
    createOption(`Visão como se tivesse manchas diante dos olhos`),
    createOption(`Ardência ou lacrimejamento dos olhos`),
    createOption(`Infecções recorrentes ou líquido nos ouvidos`),
    createOption(`Dor ou algum grau de surdez no ouvido`),
  ])

  return options
}

export default CreateOptions
