import { ChangeEvent, ReactNode } from "react"

interface RadioProps {
  children?: Readonly<ReactNode>
  className?: string
  options: Array<{
    name: string
    id: string
    label: string
    value?: number
    checked?: boolean
  }>
  required?: boolean
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => any
}

export default function Radio({
  children,
  className,
  options,
  required,
  handleChange,
}: RadioProps) {
  return (
    <div className={`my-10 ${className ?? ""}`}>
      <div className="input-description">{children}</div>
      {options.map((e) => {
        let inputRadioAttr = {
          type: "radio",
          name: e.name ?? "",
          id: e.id ?? "",
          value: e.value,
          key: `radio-input-${e.id}`,
          required: required,
          ...(handleChange
            ? {
                checked: e.checked,
                onChange: (i: ChangeEvent<HTMLInputElement>) => handleChange(i),
              }
            : { defaultChecked: e.checked }),
        }

        return (
          <label
            htmlFor={e.id ?? ""}
            key={`radio-label-${e.id}`}
            className={`flex items-start input-radio-unique`}
          >
            <input {...inputRadioAttr} />
            <span className="icon"></span>
            <span>{e.label}</span>
          </label>
        )
      })}
    </div>
  )
}
