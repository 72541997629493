import { FormEvent } from "react"

const HandleSubmit = (e: FormEvent<HTMLFormElement>, storageKey: string) => {
  e.preventDefault()

  const Form = new FormData(e.target as HTMLFormElement)

  let points = 0

  Form.forEach((e) => {
    const point = String(e).replace(/\D/g, "")
    points += parseInt(point)
  })

  localStorage.setItem(storageKey, btoa(String(points)).replace("==", ""))
}

const useForm = {
  HandleSubmit,
}

export default useForm
