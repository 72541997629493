import { ReactNode } from "react"
import Main from "../../components/Main"
import { FaTriangleExclamation } from "react-icons/fa6"
import { Link } from "react-router-dom"

interface ErrorPageProps {
  children?: ReactNode
  backToUrl?: string
  backToUrlLabel?: string
}

export default function ErrorPage({
  children,
  backToUrl,
  backToUrlLabel,
}: ErrorPageProps) {
  return (
    <Main className="flex flex-col items-center justify-center">
      <div className="my-4">
        <FaTriangleExclamation size={48} className="text-red-600" />
        <h1 className="text-lg text-red-600 font-bold">Ops!!</h1>
      </div>
      <p className="text-md text-slate-600 mb-4">{children}</p>
      {backToUrl && (
        <Link to={backToUrl} className="btn-site">
          {backToUrlLabel ?? "Voltar"}
        </Link>
      )}
    </Main>
  )
}
