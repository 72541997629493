import { useState } from "react"
import { GetUserGenre } from "../../../../functions/GetUserData"
import { createOption } from "../../../../hooks/Options"

const CreateOptions = () => {
  const G = GetUserGenre()

  const [options] = useState([
    createOption(`Fadiga ou letargia`),
    createOption(`Sensação de estar "esgotado"`),
    createOption(`Memória fraca`),
    createOption(`Depressão`),
    createOption(`Sensação de estar "aéreo" ou falta de foco`),
    createOption(`Dificuldade em tomar decisões`),
    createOption(`Dormência, queimação ou formigamento`),
    createOption(`Dores ou fraqueza muscular`),
    createOption(`Dor e/ou inchaço nas articulações`),
    createOption(`Dor abdominal`),
    createOption(`Constipação ou Intestino preso`),
    createOption(`Diarreia`),
    createOption(`Inchaço, arrotos ou gases intestinais`),
    {
      ...(G === "f" &&
        createOption(`Queimação vaginal ou secreção vaginal incômoda`)),
    },
    { ...(G === "f" && createOption(`Coceira vaginal persistente`)) },
    { ...(G === "m" && createOption(`Prostatite`)) },
    createOption(`Impotência`),
    createOption(`Perda de desejo sexual ou baixa libido`),
    { ...(G === "f" && createOption(`Endometriose ou infertilidade`)) },
    { ...(G === "f" && createOption(`Cólicas e/ou menstruações irregulares`)) },
    {
      ...(G === "f" && createOption(`Tensão pré-menstrual moderada ou fortes`)),
    },
    createOption(`Crises de ansiedade`),
    createOption(`Mãos ou pés frios e/ou calafrios`),
    createOption(`Tremores ou irritabilidade quando está com fome`),
  ])

  return options
}
export default CreateOptions
