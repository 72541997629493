import { FaCheck, FaHouse } from "react-icons/fa6"
import Main from "../../components/Main"
import { Link } from "react-router-dom"

export default function PageSuccesso() {
  return (
    <Main className="flex items-center justify-center flex-col">
      <div className="my-4 flex items-center flex-col">
        <FaCheck size={42} className="text-green-700" />
        <h1 className="text-md font-bold text-green-700">Parabéns!</h1>
      </div>
      <div className="mx-auto max-w-96">
        <p className="text-center font-bold mt-0 mb-6">
          Seu resultado foi enviado para o e-mail cadastrado.
        </p>
        <p className="bg-slate-300 rounded p-4 font-semibold text-sm my-6">
          Caso não encontre o e-mail na sua caixa de entrada, por favor,
          verifique também a sua caixa de spam.
        </p>
        <p className="font-semibold my-6">
          Agradecemos por sua participação e esperamos que os resultados possam
          ser úteis para você. Se precisar de mais alguma informação ou
          assistência, não hesite em nos contatar.
        </p>
        <p className="text-sm font-semibold my-6">
          <b>IMPORTANTE:</b> Este questionário fornece apenas uma avaliação
          rápida e não substitui um diagnóstico profissional. Se você se
          identificar com muitos dos sintomas mencionados, é recomendável buscar
          ajuda de um profissional de saúde mental para uma avaliação mais
          completa.
        </p>
        <div className="flex justify-center my-6">
          <Link to={"/"} className="btn-site">
            Início <FaHouse size={16} className="ml-2" />
          </Link>
        </div>
      </div>
    </Main>
  )
}
