import Board from "../../../components/Board"
import Main from "../../../components/Main"
import Header from "../../../components/Header"
import SectionTitle from "../../../components/SectionTitle"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"
import useForm from "../../../hooks/useForm"
import { Link, useNavigate } from "react-router-dom"
import { FormEvent, useEffect, useState } from "react"
import Submit from "../../../components/forms/submit"
import Radio from "../../../components/forms/radio"
import useValidate from "../../../hooks/useValidate"
import ErrorPage from "../../error"
import { GetUserGenre } from "../../../functions/GetUserData"
import { compileJson, uncompileJson } from "../../../functions/JSON"
import HistoricoOptions from "./types"
import isCheckedOption from "../../../functions/IsOptionChecked"

export default function Historico() {
  const navigate = useNavigate()
  const [options, setOptions] = useState<HistoricoOptions>()

  useEffect(() => {
    const savedOptions = localStorage.getItem("drk-p-1-opt")
    if (savedOptions) {
      setOptions(uncompileJson(savedOptions))
    }
  }, [])

  if (!useValidate.CheckStep("drk-p-0")) {
    return (
      <ErrorPage backToUrl={"/"} backToUrlLabel="Informar dados pessoais">
        Você não concluiu o passo anterior
      </ErrorPage>
    )
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    useForm.HandleSubmit(e, "drk-p-1")
    localStorage.setItem("drk-p-1-opt", compileJson(options))
    navigate("/principais-sintomas")
  }

  const G = GetUserGenre()

  return (
    <Main>
      <Header>Questionário de Triagem para Cândida</Header>
      <Board>
        <SectionTitle>Histórico</SectionTitle>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optinputoption1",
                value: 0,
                label: "Não",
                name: "optinputoption",
                checked: isCheckedOption(options?.optinputoption, 0),
              },
              {
                id: "optinputoption2",
                value: 35,
                label: "Sim",
                name: "optinputoption",
                checked: isCheckedOption(options?.optinputoption, 35),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optinputoption: e.target.value })
            }}
          >
            Você tomou tetraciclinas (Tetrex®, Tetramed®, Vibramicina,
            Minociclina®, Doxiciclina etc.) ou outros antibióticos para acne por
            um mês ou mais?
          </Radio>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optinfeccoes1",
                value: 0,
                label: "Não",
                name: "optinfeccoes",
                checked: isCheckedOption(options?.optinfeccoes, 0),
              },
              {
                id: "optinfeccoes2",
                value: 35,
                label: "Sim",
                name: "optinfeccoes",
                checked: isCheckedOption(options?.optinfeccoes, 35),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optinfeccoes: e.target.value })
            }}
          >
            Em algum momento de sua vida, você tomou outros antibióticos de
            "amplo espectro"* para infecções respiratórias, urinárias ou outras
            (por dois meses ou mais, ou em períodos mais curtos por quatro ou
            mais vezes dentro de um ano)?
          </Radio>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optantibioticos1",
                value: 0,
                label: "Não",
                name: "optantibioticos",
                checked: isCheckedOption(options?.optantibioticos, 0),
              },
              {
                id: "optantibioticos2",
                value: 6,
                label: "Sim",
                name: "optantibioticos",
                checked: isCheckedOption(options?.optantibioticos, 6),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optantibioticos: e.target.value })
            }}
          >
            Você já tomou um antibiótico de amplo espectro*, mesmo em uma
            oportunidade apenas?
          </Radio>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optprostatite1",
                value: 0,
                label: "Não",
                name: "optprostatite",
                checked: isCheckedOption(options?.optprostatite, 0),
              },
              {
                id: "optprostatite2",
                value: 25,
                label: "Sim",
                name: "optprostatite",
                checked: isCheckedOption(options?.optprostatite, 25),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optprostatite: e.target.value })
            }}
          >
            Em algum momento de sua vida, você foi incomodado por prostatite
            persistente, vaginite ou outros problemas afetando seus órgãos
            reprodutivos?
          </Radio>
          {G === "f" && (
            <Radio
              className="radio-wrapper"
              required={true}
              options={[
                {
                  id: "optgestante1",
                  value: 0,
                  label: "Não",
                  name: "optgestante",
                  checked: isCheckedOption(options?.optgestante, 0),
                },
                {
                  id: "optgestante2",
                  value: 3,
                  label: "Uma vez",
                  name: "optgestante",
                  checked: isCheckedOption(options?.optgestante, 3),
                },
                {
                  id: "optgestante3",
                  value: 5,
                  label: "Duas vezes ou mais",
                  name: "optgestante",
                  checked: isCheckedOption(options?.optgestante, 5),
                },
              ]}
              handleChange={(e) => {
                setOptions({ ...options, optgestante: e.target.value })
              }}
            >
              Você já esteve grávida?
            </Radio>
          )}

          {G === "f" && (
            <Radio
              className="radio-wrapper"
              required={true}
              options={[
                {
                  id: "optanticoncepcionais1",
                  value: 0,
                  label: "Não",
                  name: "optanticoncepcionais",
                  checked: isCheckedOption(options?.optanticoncepcionais, 0),
                },
                {
                  id: "optanticoncepcionais2",
                  value: 8,
                  label: "Por seis meses a dois anos",
                  name: "optanticoncepcionais",
                  checked: isCheckedOption(options?.optanticoncepcionais, 8),
                },
                {
                  id: "optanticoncepcionais3",
                  value: 15,
                  label: "Por mais de dois anos",
                  name: "optanticoncepcionais",
                  checked: isCheckedOption(options?.optanticoncepcionais, 15),
                },
              ]}
              handleChange={(e) => {
                setOptions({ ...options, optanticoncepcionais: e.target.value })
              }}
            >
              Você tomou pílulas anticoncepcionais?
            </Radio>
          )}

          <Radio
            required={true}
            className="radio-wrapper"
            options={[
              {
                id: "optcortisona1",
                value: 0,
                label: "Não",
                name: "optcortisona",
                checked: isCheckedOption(options?.optcortisona, 0),
              },
              {
                id: "optcortisona2",
                value: 6,
                label: "Por duas semanas ou menos",
                name: "optcortisona",
                checked: isCheckedOption(options?.optcortisona, 6),
              },
              {
                id: "optcortisona3",
                value: 15,
                label: "Por mais de duas semanas",
                name: "optcortisona",
                checked: isCheckedOption(options?.optcortisona, 15),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optcortisona: e.target.value })
            }}
          >
            Você tomou prednisona, decadron ou outros medicamentos semelhantes à
            cortisona ou corticóides?
          </Radio>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optsintomasperfumes1",
                value: 0,
                label: "Não",
                name: "optsintomasperfumes",
                checked: isCheckedOption(options?.optsintomasperfumes, 0),
              },
              {
                id: "optsintomasperfumes2",
                value: 5,
                label: "Sintomas leves",
                name: "optsintomasperfumes",
                checked: isCheckedOption(options?.optsintomasperfumes, 5),
              },
              {
                id: "optsintomasperfumes3",
                value: 20,
                label: "Sintomas moderados a graves",
                name: "optsintomasperfumes",
                checked: isCheckedOption(options?.optsintomasperfumes, 20),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optsintomasperfumes: e.target.value })
            }}
          >
            A exposição a perfumes, inseticidas, odores de lojas e outros
            produtos químicos provoca sintomas?
          </Radio>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optsintomasdiasumidos1",
                value: 0,
                label: "Não",
                name: "optsintomasdiasumidos",
                checked: isCheckedOption(options?.optsintomasdiasumidos, 0),
              },
              {
                id: "optsintomasdiasumidos2",
                value: 20,
                label: "Sim",
                name: "optsintomasdiasumidos",
                checked: isCheckedOption(options?.optsintomasdiasumidos, 20),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optsintomasdiasumidos: e.target.value })
            }}
          >
            Seus sintomas pioram em dias úmidos, abafados ou em lugares com
            mofo?
          </Radio>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optinfeccoespele1",
                value: 0,
                label: "Não",
                name: "optinfeccoespele",
                checked: isCheckedOption(options?.optinfeccoespele, 0),
              },
              {
                id: "optinfeccoespele2",
                value: 10,
                label: "Leve a moderado",
                name: "optinfeccoespele",
                checked: isCheckedOption(options?.optinfeccoespele, 10),
              },
              {
                id: "optinfeccoespele3",
                value: 20,
                label: "Grave ou persistente",
                name: "optinfeccoespele",
                checked: isCheckedOption(options?.optinfeccoespele, 20),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optinfeccoespele: e.target.value })
            }}
          >
            Você teve pé de atleta, micose, "coceira de atleta" ou outras
            infecções fúngicas crônicas na pele e/ou nas unhas?
          </Radio>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optdesejoporacuca1",
                value: 0,
                label: "Não",
                name: "optdesejoporacuca",
                checked: isCheckedOption(options?.optdesejoporacuca, 0),
              },
              {
                id: "optdesejoporacuca2",
                value: 10,
                label: "Sim",
                name: "optdesejoporacuca",
                checked: isCheckedOption(options?.optdesejoporacuca, 10),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optdesejoporacuca: e.target.value })
            }}
          >
            Você tem desejo por doces ou açúcar?
          </Radio>
          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optmassasoupaes1",
                value: 0,
                label: "Não",
                name: "optmassasoupaes",
                checked: isCheckedOption(options?.optmassasoupaes, 0),
              },
              {
                id: "optmassasoupaes2",
                value: 10,
                label: "Sim",
                name: "optmassasoupaes",
                checked: isCheckedOption(options?.optmassasoupaes, 10),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optmassasoupaes: e.target.value })
            }}
          >
            Você tem desejo por massas ou pães?
          </Radio>

          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optbebidasalcoolicas1",
                value: 0,
                label: "Não",
                name: "optbebidasalcoolicas",
                checked: isCheckedOption(options?.optbebidasalcoolicas, 0),
              },
              {
                id: "optbebidasalcoolicas2",
                value: 10,
                label: "Sim",
                name: "optbebidasalcoolicas",
                checked: isCheckedOption(options?.optbebidasalcoolicas, 10),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optbebidasalcoolicas: e.target.value })
            }}
          >
            Você tem desejo por bebidas alcoólicas?
          </Radio>

          <Radio
            className="radio-wrapper"
            required={true}
            options={[
              {
                id: "optfumacacigarro1",
                value: 0,
                label: "Não",
                name: "optfumacacigarro",
                checked: isCheckedOption(options?.optfumacacigarro, 0),
              },
              {
                id: "optfumacacigarro2",
                value: 10,
                label: "Sim",
                name: "optfumacacigarro",
                checked: isCheckedOption(options?.optfumacacigarro, 10),
              },
            ]}
            handleChange={(e) => {
              setOptions({ ...options, optfumacacigarro: e.target.value })
            }}
          >
            A fumaça de cigarro realmente te incomoda?
          </Radio>

          <p style={{ fontWeight: 500, fontSize: "14px" }} className="mb-6">
            *Alguns exemplos de antibióticos de amplo espectro:
            amoxicilina-clavulanato, ampicilina, amoxicilina, cefalexina,
            Bactrim®, sulfametoxazol trimetoprima, azitromicina, eritromicina,
            claritromicina, gentamicina, cefalosporinas.{" "}
          </p>

          <div className="flex justify-between">
            <Link to={"/"} className="btn-site secondary">
              <FaAngleLeft size={16} />
              Voltar
            </Link>
            <Submit>
              Próximo
              <FaAngleRight size={16} />
            </Submit>
          </div>
        </form>
      </Board>
    </Main>
  )
}
