import { Dispatch, ReactNode, SetStateAction } from "react"
import { FaX } from "react-icons/fa6"

interface PopupProps {
  children?: ReactNode
  isOpen: boolean
  setOpenState: Dispatch<SetStateAction<boolean>>
}

export default function Popup({ children, isOpen, setOpenState }: PopupProps) {
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-10 flex items-center justify-center transition ${
        isOpen
          ? "pointer-events-auto opacity-100"
          : "pointer-events-none opacity-0"
      }`}
    >
      <div className="fixed z-0 bg-slate-500 bg-opacity-50 w-full h-full top-0 left-0 backdrop-blur-sm"></div>
      <div className="relative z-1 p-4 bg-slate-100 w-full max-w-[576px]">
        <button
          onClick={() => setOpenState(false)}
          className="rounded-full bg-slate-600 text-slate-300 flex items-center justify-center w-6 h-6 ml-auto"
        >
          <FaX size={14} />
        </button>
        {children}
      </div>
    </div>
  )
}
